<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="wxq_course_header">
      <div class="wxq_course_header_left">
        <el-form class="form" :inline="true">
          <el-row>
            <el-col :span="24">
              <el-form-item class="wxq_label" label="资讯分类">
                <div class="wxq_classify">
                  <span
                    class="wxq_classify_item"
                    :class="{ wxq_classify_active: allStatusCourseLabel === 0 }"
                    @click="clickCourseLabelMenu('oneAll')"
                  >
                    全部
                  </span>
                  <span
                    class="wxq_classify_item"
                    :class="{
                      wxq_classify_active:
                        item.newsType == clickCoursenewsTypetatus,
                    }"
                    v-for="item in subjectList"
                    :key="item.newsType"
                    @click="clickCourseLabelMenu('oneItem', item)"
                  >
                    {{ item.newsType }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关键词">
                <el-input
                  v-model="formData.newsTitle"
                  placeholder="请输入关键词"
                  clearable
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="资讯状态">
                <el-select
                  v-model="formData.state"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in courseState"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button class="btn btn_blue btn_search" @click="search"
                >搜索</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="wxq_course_header_right">
        <div class="wxq_count">
          <div class="wxq_title">资讯总数</div>
          <div class="wxq_count_number color_blue">
            <span class="wxq_number">{{ rowCount }}</span>
            <span>篇</span>
          </div>
          <div class="wxq_construction btn_orange box_shadow" @click="newBuild">
            <i class="el-icon-plus"></i>
            <span>新建资讯</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dataList.length > 0" class="wxq_course_main">
      <div class="wxq_course_detail" v-for="item in dataList" :key="item.id">
        <img :src="item.imgUrl" alt="" />
        <p class="wxq_detail_title">{{ item.newsTitle }}</p>
        <p class="wxq_detail_time">
          更新于&nbsp;
          <span>{{ item.updateTime }}</span>
        </p>
        <div class="wxq_detail_footer">
          <div class="wxq_footer_box" v-if="item.state == '未发布'">
            <p>0</p>
            <p>阅读量</p>
          </div>
          <div class="wxq_footer_box" v-else>
            <p>{{ item.viewCount ? item.viewCount : 0 }}</p>
            <p>阅读量</p>
          </div>
          <div class="wxq_footer_box hh_style">
            <p>{{ item.state }}</p>
            <p>状态</p>
          </div>
          <div class="wxq_footer_box">
            <p>{{ item.newsType }}</p>
            <p>分类</p>
          </div>
        </div>
        <!--基础设置-->
        <div class="wxq_basic_setting">
          <el-dropdown
            trigger="click"
            @command="handleCommand($event, item.id, item)"
          ><!-- state -->
            <span class="el-dropdown-link">
              <!-- 基础设置 -->
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="watchFist">
                <img
                  src="@/assets/img/backStageImg/myCourse/yulan-icon.png"
                  alt=""
                />
                预览
              </el-dropdown-item>
              <el-dropdown-item
                command="compile"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/compile-icon.png"
                  alt=""
                />
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                command="publish"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/send-icon.png"
                  alt=""
                />
                提交审核
              </el-dropdown-item>
              <el-dropdown-item command="history">
                <img src="@/assets/img/backStageImg/history-icon.png" alt="" />
                审核记录
              </el-dropdown-item>
              <el-dropdown-item
                command="undercarriage"
                :disabled="
                  item.state == '已下架' ||
                  item.state == '已驳回' ||
                  item.state == '未发布'
                "
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/sold-out-icon.png"
                  alt=""
                />
                下架
              </el-dropdown-item>
              <el-dropdown-item
                command="delete"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/delete-icon.png"
                  alt=""
                />
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <none-data v-else :type="type"></none-data>
    <pagination
      v-if="totalCount >= 1"
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="child"
    />
    <!--审核记录-->
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_blue: currentStateName == '待审核',
              color_orange: currentStateName == '审核中',
              color_green: currentStateName == '已发布',
              color_red: currentStateName == '已驳回',
              color_gray: currentStateName == '已下架' || '未发布',
            }"
            >{{ currentStateName }}</span
          >
          <h2 :title="lecturerTitle">{{ lecturerTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{ item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import CommonUtils from "@/utils";
import API from "@/apis/backStageEndAPI/consultManageAPI";
import noneData from "@comps/backStageComponent/noneData";
import headerBar from "@comps/backStageComponent/layout/headerBar/headerBar";
import Pagination from "@/components/backStageComponent/pagination";
export default {
  name: "consultManagement",
  components: {
    headerBar,
    Pagination,
    noneData,
  },
  data() {
    return {
      type: "consult",
      formData: {
        page: 1,
        pageSize: 12,
        newsTitle: "",
        newsType: "",
        state: "",
      },
      allStatusCourseLabel: 0,
      clickCoursenewsTypetatus: "", //学科状态
      subjectList: [],
      dataList: [],
      rowCount: 0,
      totalCount: 0,
      courseState: [
        //资讯状态
        { id: 0, label: "未发布" },
        { id: 1, label: "已发布" },
        { id: 2, label: "待审核" },
        { id: 3, label: "已驳回" },
        { id: 9, label: "已下架" },
      ],
      currentRow: '',
      currentStateName: '',
      lecturerTitle: "",
      auditCount: 0,
      auditList: [],
      recordData: {
        itemId: "",
        itemType: 1, //资讯
        page: 1,
        pageSize: 10,
      },
      isDrawerVisible: false, //审核历史开关
    };
  },
  mounted() {
    this.getConsultList(true);
    this.getCourseLabel();
  },
  methods: {
    getCourseLabel() {
      //资讯分类
      dictionaryAPI.getDictionaryData("consult_code").then((res) => {
        this.subjectList = res;
      });
    },
    search() {
      //搜索
      this.formData.page = 1;
      if (this.totalCount > 12) {
        this.$refs.child.handleSize(1);
        this.getConsultList();
      } else {
        this.getConsultList(); //获取列表
      }
    },
    handleCommand(val, id , item) {
      if (val == "watchFist") {
        //预览
        this.$router.push({ path: "/previewConsult", query: { id: id } });
      } else if (val == "delete") {
        this.$confirm("确定要删除该课程吗？", {})
          .then((res) => {
            this.deleteConsult(id);
          })
          .catch(() => {});
      } else if (val == "compile") {
        //编辑
        this.$router.push({
          path: "/addConsult",
          query: { id: id },
        });
      } else if (val == "publish") {
        //发布
        this.$confirm("确定要提交审核该资讯吗？", {})
          .then((res) => {
            let params = {
              id: id,
              state: 2,
            };
            this.updateConsult(params);
          })
          .catch(() => {});
      }else if(val == 'history') {
        this.getAuditLogList(item); //审核记录
      } else if (val == "undercarriage") {
        //下架
        this.$confirm("确定要下架该资讯吗？", {})
          .then((res) => {
            let params = {
              id: id,
              state: 9,
            };
            this.updateConsult(params);
          })
          .catch(() => {});
      }
    },
    async getAuditLogList(item) {
      //获取审核记录
      this.currentRow = item;
      this.currentStateName = item.state;
      this.lecturerTitle = item.newsTitle;
      this.recordData.itemId = item.id;
      let data = await API.getAuditLogList(
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    auditPageChange(val) {
      //审核记录页面跳转
      this.recordData.page = val;
      this.getAuditLogList(this.currentRow);
    },
    updateConsult(params) {
      API.testConsult(params).then((res) => {
        this.getConsultList();
      });
    },
    deleteConsult(id) {
      API.delConsult(id).then((res) => {
        this.getConsultList();
      });
    },
    getConsultList(flag) {
      API.newsList(CommonUtils.parseToParams(this.formData)).then((res) => {
        res.dataList.map((item) => {
          switch (item.state) {
            case 0:
              item.state = "未发布";
              break;
            case 1:
              item.state = "已发布";
              break;
            case 2:
              item.state = "待审核";
              break;
            case 3:
              item.state = "已驳回";
              break;
            case 9:
              item.state = "已下架";
          }
        });
        this.dataList = res.dataList;
        this.totalCount = res.rowCount;
        // this.rowCount = res.rowCount;
        if (flag) {
          this.rowCount = res.rowCount;
        }
      });
    },
    newBuild() {
      this.$router.push("addConsult");
    },
    clickCourseLabelMenu(type, data) {
      if (type == "oneAll") {
        this.allStatusCourseLabel = 0;
        this.clickCoursenewsTypetatus = "";
        this.formData.newsType = "";
        this.getConsultList();
      } else {
        this.allStatusCourseLabel = "";
        this.clickCoursenewsTypetatus = data.newsType;
        this.formData.newsType = data.newsType;
        this.getConsultList();
      }
    },
    pageChange(val) {
      this.formData.page = val;
      this.getConsultList();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  color: #7d8592;
  font-weight: 800;
  .color_gray {
    color: #dcdcdc;
  }
  .btn_search {
    margin-left: 16px;
  }
  .wxq_course_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    padding: 24px;
    width: 100%;
    border-radius: 24px;
    background: #fff;
    .wxq_course_header_left {
      position: relative;
      padding-right: 20px;
      // width: 82%;
      height: 100%;
      font-size: 14px;
      flex: 1;
    }
    .wxq_course_header_left:before {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 10%;
      left: auto;
      width: 1px;
      height: 80%;
      background-color: #f1f1f1;
      content: "";
    }
    .wxq_course_header_right {
      position: relative;
      padding: 0 16px;
      width: 240px;
      height: inherit;
      text-align: center;
      .wxq_count {
        position: relative;
        width: 100%;
        height: 90%;
        min-height: 160px;
        border-radius: 24px;
        background: #f4f9fd;
        text-align: center;
        .wxq_title {
          padding-top: 12px;
          color: #666;
        }
        .wxq_count_number {
          position: relative;
          top: 12%;
          height: 48px;
          font-weight: 800;
          font-size: 16px;
          line-height: 48px;
          .wxq_number {
            margin-right: 6px;
            font-size: 60px;
          }
        }
        .wxq_construction {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 48px;
          border-radius: 14px;
          cursor: pointer;
          span {
            color: #fff;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
          }
          .el-icon-plus:before {
            margin-right: 13px;
            color: #fff;
            font-weight: 800;
            font-size: 20px;
          }
        }
      }
    }
    .form {
      .wxq_label {
        ::v-deep.el-form-item__label {
          line-height: 28px;
        }
      }
      ::v-deep.el-form-item {
        margin-bottom: 10px;
      }
      ::v-deep.el-form-item__content {
        line-height: normal;
      }
      .wxq_classify {
        .wxq_classify_item {
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 4px 10px;
          border-radius: 7px;
          background: #f4f9fd;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }
        .wxq_classify_active {
          background: var(--colorBlue);
          color: #fff;
          font-weight: 600;
        }
      }
      .wxq_courses_type {
        margin-bottom: 0;
        .el-radio-group {
          line-height: 50px;
        }
      }
    }
  }
  .gl_drawer {
    width: 100%;
    box-sizing: border-box;
    .gl_drawer_title {
      position: relative;
      padding: 0 40px 0 70px;
      background: var(--colorBlue);
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      h2 {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .gl_state {
        position: absolute;
        top: 50%;
        left: 8px;
        padding: 4px 8px;
        border-radius: 16px;
        background: #fff;
        font-size: 12px;
        line-height: 100%;
        transform: translateY(-50%);
      }
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_empty {
        color: #666;
        text-align: center;
      }
      li {
        margin-bottom: 16px;
        padding: 8px;
        background: #f7faff;
        .gl_audit_user {
          padding-right: 6px;
          color: var(--colorBlue);
        }
        .gl_audit_time {
          float: right;
          color: #666;
        }
        .gl_audit_info {
          padding: 4px;
        }
      }
    }
  }
}
::v-deep.el-dialog__wrapper {
  .el-dialog {
    .el-dialog__body {
      padding: 30px 30px 30px 10px;
      text-align: left;
    }
  }
}
.wxq_course_main {
  display: flex;
  flex-wrap: wrap;
  .wxq_course_detail {
    position: relative;
    overflow: hidden;
    margin: 0 8px 18px;
    padding: 10px;
    width: 368px;
    height: 346px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);
    img {
      width: 100%;
      height: 214px;
      border-radius: 24px;
    }
    .wxq_detail_title {
      overflow: hidden;
      margin-top: 8px;
      padding-left: 10px;
      color: #333;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 800;
      font-size: 16px;
    }
    .wxq_detail_time {
      display: flex;
      margin-bottom: 8px;
      padding-left: 10px;
      line-height: 26px;
    }
    .wxq_detail_footer {
      display: flex;
      // margin-top: 12px;
      justify-content: space-around;
      height: 72px;
      .wxq_footer_box {
        padding: 0 8px;
        width: 30%;
        height: 42px;
        border-right: 1px solid #f1f1f1;
        text-align: center;
        p {
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p:first-of-type {
          color: var(--colorBlue);
          font-size: 16px;
        }
        p:last-of-type {
          font-size: 12px;
          line-height: 24px;
        }
      }
      .hh_style {
        cursor: pointer;
      }
      .wxq_footer_box:last-of-type {
        border: none;
      }
    }
    .wxq_basic_setting {
      position: absolute;
      top: 22px;
      right: 22px;
      padding: 8px 0 8px 9px;
      width: 35px;
      height: 36px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.5);
      box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.4);
      cursor: pointer;
      .el-dropdown {
        color: #fff;
        font-weight: 400;
        .el-icon--right {
          margin-left: 2px;
          font-size: 16px;
          transform: rotate(90deg);
        }
      }
    }
  }
}
.pagination {
  padding-top: 0;
}
::v-deep.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
    width: 22px;
  }
}
</style>
